<template>
  <asom-card>
    <form class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
      <asom-alert class="col-span-3" v-if="error" variant="error" :error-message="error" />

      <div class="col-span-3 flex flex-wrap">
        <div class="mt-5 font-medium mr-3">Report By* :</div>
        <div class="mt-3">
          <asom-input-text :disabled="true" type="text" v-model="userName" />
        </div>
        <div class="mt-5 font-medium mr-3 ml-10">Report Date :</div>
        <div class="mt-3">
          <asom-input-text :disabled="true" type="text" v-model="dateCreated" />
        </div>
      </div>

      <div class="col-span-3 grid sm:grid-cols-1 md:grid-cols-3 gap-4">
        <asom-form-field label="Line" required error="Please select a MRT line">
          <asom-input-select :disabled="true" v-model="filters.line" :options="lineOptions" />
        </asom-form-field>
        <asom-form-field label="Zone" required>
          <asom-input-select :disabled="true" v-model="filters.zoneId" :options="zoneList" />
        </asom-form-field>
        <asom-form-field label="Station" required error="Please select a MRT station">
          <asom-input-select :disabled="true" v-model="filters.station" :options="stationOptions" />
        </asom-form-field>
      </div>

      <div class="col-span-3 flex flex-wrap">
        <div class="mt-12 font-medium mr-3">Manual Change balance as at End-of-Traffic date (on)</div>
        <asom-form-field label="Date" required>
          <asom-input-text :disabled="true" type="text" v-model="filters.dateFrom" />
        </asom-form-field>
        <div class="mt-12 font-medium ml-3 mr-1">,</div>
        <div class="mt-12 font-medium">recorded on the Station Manual Change Float Record.</div>
      </div>
      <div class="col-span-3 flex flex-wrap">
        <div class="mt-5 font-medium mr-3">MONTHLY MANUAL CHANGE COUNT IS FOR YEAR :</div>
        <div class="mt-3">
          <asom-input-text class="font-semibold" :disabled="true" type="text" v-model="filters.year" />
        </div>
        <div class="mt-5 font-medium ml-3 mr-3">, and MONTH of</div>
        <div class="mt-3">
          <asom-input-text class="font-semibold" :disabled="true" type="text" v-model="filters.month" />
        </div>
      </div>
      <div class="col-span-3 flex flex-wrap">
        <div class="mt-5 font-medium mr-3">Manual Change balance as at End-of-Traffic recorded on the 'Station
          Manual Change '. ($)</div>
        <div class="mt-3">
          <asom-input-text :disabled="true" class="font-semibold" type="text" v-model="psmAmount" />
        </div>
        <div class="mt-2">
          *All Manual Change bags with the status of closed, opened & used are to be declared, including those in the safe.
        </div>
      </div>
      <div class="col-span-3 flex flex-wrap">
        <div class="mt-5 font-medium mr-3">Remark:</div>
        <div class="mt-3 w-100">
          <asom-input-textarea disabled style="width: 600px"  v-model="remark"/>
        </div>
      </div>

      <!-- 表格 -->
      <div v-if="collectionList.length" class="flex flex-col col-span-3">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-gray-200 sm:rounded-lg">

              <div v-for="(item, i) in collectionList" :key="i" class="space-y-2 align-center">
                <div v-for="(li, p) in item.teBags" :key="p"
                  class="px-6 py-4 grid sm:grid-cols-1 text-sm md:grid-cols-3 gap-4">
                  <div class="flex flex-row items-center space-y-2">
                    TAMPER EVIDENT BAG S/NO.
                    <asom-input-text :disabled="true" class="ml-3" type="text" v-model="li.bagNo" />
                  </div>
                  <div class="flex flex-row items-center space-y-2">
                    CASH DECLARATION FORM S/NO.
                    <asom-input-text :disabled="true" class="ml-3" type="text"
                      v-model="li.cashDeclarationFormRefrenceNo" />
                  </div>
                  <div class="flex flex-row items-center space-y-2">
                    AMOUNT ($)
                    <asom-input-text :disabled="true" class="ml-3" type="text" v-model="li.amount" />
                  </div>
                </div>
              </div>

              <div class="border-t bg-gray-50 flex justify-end space-x-4">
                <div
                  class="px-6 py-3 text-left font-large text-black text-sm font-semibold flex flex-wrap items-center">
                  Total Amount Declared
                  <asom-input-text class="font-semibold ml-3" :disabled="true" type="text" v-model="totalNum" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="col-span-3 text-center mt-8">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>

      <div class="col-span-3 flex justify-end space-x-4">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </form>
  </asom-card>
</template>

<script>
import get from 'lodash.get'
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import moment from "moment";
import { getMonthEndDeclarAtion } from "@/services/cashManagement.service";
import { getZoneList } from '@/services/smrtResource.service'
import {
  displayUtcDate,
  displayUtcDateTime,

} from "@/helpers/dateTimeHelpers";

export default {
  name: "MonthDeclarationDetails",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filters: {
        line: null,
        station: null,
        zoneId: null,

        dateFrom: null,
        year: null,
        month: null,
      },
      error: null,
      zoneList: [],
      psmAmount: null,
      collectionList: [],
      remark: '',
      dateCreated: '',
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      // userId: "auth/userId",
      userName: "auth/userName",
      stationId: "selectedStation/id",
      lineOptions: "smrtResource/lineOptions",
      lineId: "selectedStation/lineId",
      // canSelectLine: 'auth/canSelectLine',  //是否管理员
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    totalNum() {
      return this.collectionList.reduce((l, li) => {
        return l + li.teBags.reduce((p, item) => {
          return p + parseInt(item.amount) || 0;
        }, 0);
      }, 0 || 0);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const query = this.$route.query;
      this.setUserData(query.id);
      this.initZone();
    },
    async setUserData(id) {
      const res = await getMonthEndDeclarAtion(id);
      this.loading = false;
      if (res.success) {
        const payload = res.payload;
        const data = res.payload.data;
        this.collectionList = [payload];
        this.psmAmount = data.manualChangeBalance;

        this.filters.dateFrom = displayUtcDate(data.dateCreated);
        const dayNum = this.filters.dateFrom.split('/')[0];
        const monthNum = this.filters.dateFrom.split('/')[1];
        const yearNum = this.filters.dateFrom.split('/')[2];
        this.filters.year = yearNum;
        this.filters.month = moment(`${yearNum}-${monthNum}-${dayNum}`).format('MMMM');
        this.remark = data.remark
        this.dateCreated = displayUtcDateTime(moment(data.dateCreated).format('YYYY-MM-DD 00:00:00'));
        const zoneRes = await getZoneList({ skip: 0, take: 1000 })
        const zoneOptions = zoneRes.payload.list;
        const currentZone = zoneOptions.find(zone => zone.zoneId === data.zoneId);
        const stationOptions = this.$store.getters["smrtResource/stationOptionsByLineId"](data.lineId);

        // 获取该线路下的站点
        this.filters.line = this.lineOptions.find(line => line.value === data.lineId);
        this.filters.station = stationOptions.find(station => station.value === data.stationId)
        this.filters.zoneId = {
          label: currentZone.zoneName,
          value: currentZone.zoneId
        }
      }
    },
    async initZone() {
      const zoneRes = await getZoneList({ skip: 0, take: 100 })
      if (zoneRes.success) {
        const zone = zoneRes.payload.list;
        this.zoneList = zone.map(zone => ({
          value: zone.zoneId,
          label: zone.zoneName
        }))
        const userZone = zone.find(l => l.zoneId == this.filters.zoneId);
        if (userZone) {
          this.filters.zoneId = {
            value: userZone.zoneId,
            label: userZone.zoneName
          }
        }
      }
    }
  },
};
</script>
